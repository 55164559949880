import React from 'react'
import styled from 'styled-components'

import { Container } from 'common/UI'
import { MediaStoryblok } from 'common/types'
import { Media } from 'modules/shared'

type Props = {
  block: MediaStoryblok
}

const MediaBlock = ({ block, ...props }: Props): JSX.Element => {
  const { source, width } = block

  return (
    <>
      {source?.filename && (
        <MediaContainer {...props}>
          <Wrapper width={width}>
            <Media
              source={source}
              css={{ maxHeight: width === 'small' ? '26.25rem' : '100%' }}
            />
          </Wrapper>
        </MediaContainer>
      )}
    </>
  )
}

export { MediaBlock }

const MediaContainer = styled(Container)`
  padding-top: 1rem;
  padding-bottom: 1rem;

  max-width: none;
  width: 100%;
  display: flex;
  justify-content: center;

  ${({ theme }) => theme.media.md} {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`

const Wrapper = styled.div<{ width?: MediaStoryblok['width'] }>`
  width: 100%;
  width: ${({ width }) => {
    const variants: Record<string, string> = {
      large: '82rem',
      medium: '60rem',
      small: '37rem',
    }
    return variants[width || 'large']
  }};
`
